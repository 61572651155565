<template>
  <div>
    <div>
      <b-row v-if="driverCars.length > 0">
        <b-col cols="12">
          <b-card class="my-card m-0">
            <b-table
              striped
              small
              id="myTables"
              bordered
              selectable
              selected-variant="primary"
              select-mode="single"
              responsive="sm"
              :items="driverCars"
              :fields="fields"
              @row-dblclicked="editForm"
              head-row-variant="secondary"
            >
              <template #cell(checkbox)="row">
                <b-form-checkbox
                  v-model="row.detailsShowing"
                  plain
                  class="vs-checkbox-con"
                  @change="row.toggleDetails"
                >
                </b-form-checkbox>
              </template>
              <template #cell(active)="data">
                <span v-if="data.item.active == 1"> Активен </span>
                <span v-else> Не активен </span>
              </template>
              <template #cell(model)="data">
                <div>
                  {{ data.item.car_brand + " " + data.item.model }}
                </div>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <modal-car-edit :id="id" @refresh="refresh" :fuel_types="fuel_types" />
    <modal-car @refresh="refresh" :fuel_types="fuel_types" />
  </div>
</template>

<script>
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
import modalCarEdit from "@/views/component/Modal/ModalCar/modalCarEdit.vue";
import modalCar from "@/views/component/Modal/ModalCar/modalCar.vue";
export default {
  components: {
    modalCarEdit,
    modalCar,
  },
  data() {
    return {
      driverCars: [],
      fuel_types: [],
      id: null,
      fields: [
        { key: "checkbox", label: "", thStyle: { width: "30px" } },
        { key: "id", label: "Id", sortable: true, thStyle: { width: "40px" } },
        {
          key: "model",
          label: "Модель",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "category",
          label: "Категория",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "class_car",
          label: "Класс",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "color",
          label: "Цвет",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "condition",
          label: "Сосотояние",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "year_of_issue",
          label: "Год выпуска",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "car_number",
          label: "Номер машины",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "count_seat",
          label: "Количество мест",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "license_number",
          label: "Номер лицензии",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "legal_entity",
          label: "Юридическое лицо",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "active",
          label: "Активность",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "dop_info",
          label: "Доп. инфо",
          sortable: true,
          thStyle: { width: "150px" },
        },
      ],
    };
  },
  methods: {
    editForm(id) {
      this.id = id.id;
      setTimeout(() => {
        this.$bvModal.show("modalCarEdit");
      }, 0);
    },
    refresh() {
      this.$http.get(`drivers/${this.$route.query.filter}/cars`).then((res) => {
        this.driverCars = res.data;
        this.$store.commit("pageData/setdataCount", this.driverCars.length);
      });
      this.$http.get("cars/fuel-types").then((res) => {
        this.fuel_types = res.data;
      });
    },
  },
  mounted() {
    resizeable();
    this.$store.commit("pageData/setdataCount", null);
    this.refresh();
  },
  watch: {
    "$route.query.filter": function (newPage, oldPage) {
      if (newPage !== oldPage) {
        this.$http
          .get(`drivers/${this.$route.query.filter}/cars`)
          .then((res) => {
            this.driverCars = res.data;
            this.$store.commit("pageData/setdataCount", this.driverCars.length);
          });
      }
    },
  },
};
</script>
